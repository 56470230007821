import AppProvider from 'providers/AppProvider'
import React from 'react'
import ReactDOM from 'react-dom/client'
import BreakpointsProvider from 'providers/BreakpointsProvider'
import SettingsPanelProvider from 'providers/SettingsPanelProvider'
import { RouterProvider } from 'react-router-dom'
import { router } from 'Routes'
import ChatWidgetProvider from 'providers/ChatWidgetProvider'
import 'animate.css'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const avoid_redirect = [
  '/',
  '/login',
  '/forgot-password',
  '/reset-password',
  '/lock'
]
if (
  localStorage.getItem('token') == null &&
  avoid_redirect.indexOf(window.location.pathname) == -1
) {
  localStorage.setItem('user', '')
  localStorage.setItem('role', '')
  window.location.href = '/'
}

// eslint-disable-next-line
var tempToast: undefined | any

function updateOnlineStatus() {
  if (navigator.onLine) {
    localStorage.setItem('offline', '0')
    if (tempToast) {
      toast.update(tempToast, {
        isLoading: false,
        render: "You're back online!",
        type: 'success',
        autoClose: 1000
      })
    }
  } else {
    localStorage.setItem('offline', '1')
    tempToast = toast("Ooh, You're offline!", {
      position: 'bottom-center',
      autoClose: false,
      type: 'error',
      hideProgressBar: true,
      pauseOnHover: false,
      draggable: false,
      isLoading: true,
      closeButton: false,
      theme: 'colored'
    })
  }
}

setInterval(() => {
  if (localStorage.getItem('sess_expired') != null) {
    localStorage.removeItem('sess_expired')
    window.location.href = '/lock'
  }

  const toastData = localStorage.getItem('toast')
  if (toastData != null) {
    const toastParsedData: {
      type: 'info' | 'success' | 'warning' | 'error'
      message: string
      loading: boolean
      nextText: string
      position: string
      infinite: string
    } = JSON.parse(toastData)

    const toastElement = toast(toastParsedData.message, {
      position: 'top-right',
      autoClose: 3000,
      type: toastParsedData.type,
      hideProgressBar: false,
      pauseOnHover: true,
      draggable: true,
      isLoading: toastParsedData.loading,
      theme: 'light'
    })

    if (toastParsedData.nextText != null) {
      setTimeout(() => {
        toast.update(toastElement, {
          isLoading: false,
          render: toastParsedData.nextText,
          type: 'info',
          autoClose: 2000
        })
      }, 4000)
    }
    localStorage.removeItem('toast')
  }

  window.addEventListener('online', updateOnlineStatus)
  window.addEventListener('offline', updateOnlineStatus)
}, 100)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <AppProvider>
      <SettingsPanelProvider>
        <ChatWidgetProvider>
          <BreakpointsProvider>
            <RouterProvider router={router} />
            <ToastContainer style={{ zIndex: 9999, height: '10%' }} />
          </BreakpointsProvider>
        </ChatWidgetProvider>
      </SettingsPanelProvider>
    </AppProvider>
  </React.StrictMode>
)
