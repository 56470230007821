import Avatar from 'components/base/Avatar'
import avatar from 'assets/img/team/30.webp'
import Button from 'components/base/Button'
import { Form, Spinner } from 'react-bootstrap'
import { useState } from 'react'
import apiRequest from 'apiRequest'
import { useNavigate } from 'react-router-dom'

type statusColor = 'success' | 'danger' | 'primary'

const LockScreenForm = () => {
  const navigate = useNavigate()

  const [password, setPassword] = useState('')

  const [passwordError, setPasswordError] = useState(false)

  const [loginText, setLoginText] = useState('Re-authenticate')
  const [statusColor, setStatusColor] = useState<statusColor>('primary')

  const [disableButton, setDisableButton] = useState(false)

  const validateEmail = (email: string | null) => {
    if (email == null) {
      return false
    } else {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        return true
      } else {
        return false
      }
    }
  }

  const login = () => {
    setPasswordError(false)
    const username = localStorage.getItem('sess_expired_user')

    if (!validateEmail(username)) {
      localStorage.removeItem('sess_expired_user')
      window.location.href = '/'
    }

    if (password == '') {
      setPasswordError(true)
      return
    }

    setLoginText('Authorizing')
    setDisableButton(true)

    const API = new apiRequest('https://demo.globalcloudmedia.com/authorize')
    API.send(
      {
        username: username,
        password: password
      },
      (data: {
        status: string
        token: string
        message: string
        user: string
        last_logined: string
        role: string
      }) => {
        if (data.status == 'success') {
          setStatusColor('success')
          setLoginText('Welcome')
          localStorage.setItem('token', data.token)
          localStorage.setItem('user', data.user)
          localStorage.setItem('role', data.role)
          localStorage.setItem('last_logined', data.last_logined)

          localStorage.setItem(
            'toast',
            JSON.stringify({
              type: 'success',
              isLoading: true,
              message: 'Re-authenticated Successfully',
              nextText: 'Session Only Valid For This Device Only'
            })
          )

          localStorage.removeItem('sess_expired_user')
          setTimeout(() => {
            navigate('/dashboard', { replace: true })
          }, 100)
        } else {
          setLoginText(data.message)
          setStatusColor('danger')
          setTimeout(() => {
            setStatusColor('primary')
            setLoginText('Re-authenticate')
            setDisableButton(false)
          }, 2000)
        }
      }
    )
  }

  return (
    <div>
      <div className="text-center mb-5">
        <Avatar size="4xl" src={avatar} className="mb-3 d-inline-block" />
        <h2 className="text-body-highlight">
          <span className="fw-normal text-danger">Session Expired</span>
        </h2>
        <p className="text-body-tertiary">
          Please Re-enter Your Password to Access the System
        </p>
      </div>
      <Form>
        <Form.Control
          className="mb-3"
          id="password"
          type="password"
          placeholder="Enter Password"
          onChange={e => setPassword(e.target.value)}
          required={true}
          style={{ borderColor: passwordError ? 'red' : '' }}
        />
        <Button
          type="submit"
          variant={statusColor}
          onClick={login}
          className="w-100 mb-3"
          disabled={disableButton}
        >
          {loginText}
          {loginText === 'Authorizing' && (
            <Spinner
              animation="grow"
              size="sm"
              style={{ width: 12, height: 12 }}
              className="ms-2"
              as="span"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </Form>
    </div>
  )
}

export default LockScreenForm
