import React from 'react'
import { Table, FormControl, Form, Col, Row, Badge } from 'react-bootstrap'
import ReactSelect from 'components/base/ReactSelect'
import { useReducer, useState } from 'react'

import apiRequest from 'apiRequest'

import { Item, SelectType, DB_Item } from '../../../Types'

const OptionalItemsTab = (props: {
  setoptionalItems: (items: Item[]) => void
  items: Item[]
}) => {
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const [searchedItems, setSearchedItems] = useState<SelectType[]>([])
  const [addNew, setAddNew] = useState(false)

  const searchItems = (keyword: string) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/items/search',
      true
    )
    API_REQUEST.send(
      { keyword: keyword, type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setSearchedItems(data.data)
        }
      }
    )
  }

  // eslint-disable-next-line
  const addNewItem = (event: any) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/items/get',
      true
    )
    API_REQUEST.send(
      { item: typeof event === 'object' ? event.value : event },
      (data: { status: string; message: string; data: DB_Item }) => {
        if (data.status == 'success') {
          props.setoptionalItems([
            ...props.items,
            {
              item: data.data.id,
              name: data.data.name,
              description: data.data.description,
              discount_amount: 0,
              discount_percent: 0,
              quantity: 0,
              free_quantity: '',
              rate: parseFloat(data.data.default_price),
              exp_date: '',
              unit: data.data.unit,
              total: 0
            }
          ])
        }
      }
    )
    setAddNew(false)
  }

  const calculteTotal = (item: Item) => {
    const total = item.rate * item.quantity

    let discount = 0
    if (item.discount_percent > 0) {
      discount = (total * item.discount_percent) / 100
      item.discount_amount = discount
    } else {
      discount = item.discount_amount
    }

    item.total = total - discount
  }

  const AddNewRow = () => {
    setAddNew(true)
  }

  return (
    <Table hover size="sm">
      <thead className="dark__text-gray-100 dark__bg-gray-900">
        <tr>
          <th>#</th>
          <th>Item</th>
          <th>Description</th>
          <th>Item Qty</th>
          <th>Free Qty</th>
          <th>Rate</th>
          <th>Discount</th>
          <th>Tax</th>
          <th>Exp.Date</th>
          <th style={{ textAlign: 'right' }}>Amount</th>
        </tr>
      </thead>
      <tbody>
        {props.items.map((item, index) => (
          <tr key={index}>
            <td colSpan={2}>
              <FormControl
                type="text"
                placeholder="Name"
                className="w-100"
                style={{ width: '100%' }}
                defaultValue={item.name}
                onChange={e => (item.name = e.target.value)}
                readOnly
              />
            </td>
            <td>
              <FormControl
                type="text"
                placeholder="Description"
                className="w-100"
                style={{ width: '100%' }}
                defaultValue={item.description}
                onChange={e => (item.description = e.target.value)}
              />
            </td>
            <td>
              <FormControl
                type="number"
                placeholder="Item Qty"
                className="w-100"
                style={{ width: '100%' }}
                defaultValue={item.quantity}
                onChange={e => {
                  item.quantity = parseFloat(e.target.value)
                  calculteTotal(item)
                  forceUpdate()
                }}
                max={item.max_qty}
              />
            </td>
            <td>
              <FormControl
                type="number"
                placeholder="Free Qty"
                className="w-100"
                style={{ width: '100%' }}
                defaultValue={item.free_quantity}
                onChange={e => {
                  item.free_quantity = e.target.value
                }}
              />
            </td>
            <td>
              <FormControl
                type="number"
                step={0.01}
                placeholder="Rate"
                className="w-100"
                style={{ width: '100%' }}
                defaultValue={item.rate}
                onChange={e => {
                  item.rate = parseFloat(e.target.value)
                  calculteTotal(item)
                  forceUpdate()
                }}
              />
            </td>
            <td>
              <Row style={{ gap: '0px' }}>
                <Col xs={1} style={{ padding: '0px' }}></Col>
                <Col xs={4} style={{ padding: '0px' }}>
                  <FormControl
                    type="number"
                    step={0.01}
                    placeholder="%"
                    onChange={e => {
                      item.discount_percent = parseFloat(e.target.value)
                      calculteTotal(item)
                      forceUpdate()
                    }}
                  />
                </Col>
                <Col xs={6} style={{ padding: '0px' }}>
                  <FormControl
                    type="number"
                    placeholder="0.00"
                    className="w-100"
                    style={{ width: '100%' }}
                    value={item.discount_amount}
                    onChange={e => {
                      item.discount_amount = parseFloat(e.target.value)
                      calculteTotal(item)
                      forceUpdate()
                    }}
                  />
                </Col>
              </Row>
            </td>
            <td>
              <Form.Select id="discount_type">
                <option value="1" selected>
                  NBT (0%)
                </option>
              </Form.Select>
            </td>
            <td>
              <FormControl
                type="date"
                placeholder="Exp. Date"
                className="w-100"
                style={{ width: '100%' }}
                readOnly={true}
                defaultValue={item.exp_date}
                onChange={e => (item.exp_date = e.target.value)}
              />
            </td>
            <td style={{ textAlign: 'right' }}>
              <FormControl
                type="number"
                step={0.01}
                placeholder="Total"
                className="w-100"
                style={{ width: '100%' }}
                readOnly={true}
                value={item.total}
                defaultValue={item.total}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        {addNew && (
          <tr>
            <td colSpan={3}>
              <ReactSelect
                options={searchedItems}
                placeholder="Select item..."
                onChange={addNewItem}
                onBlur={() => setAddNew(false)}
                menuIsOpen={addNew}
                autoFocus={addNew}
                onInputChange={searchItems}
              />
            </td>
            <td colSpan={5}></td>
          </tr>
        )}
        <tr>
          <td></td>
          <td>
            <p
              onClick={AddNewRow}
              style={{
                textDecoration: 'none',
                cursor: 'pointer',
                color: 'blue',
                fontSize: '13px'
              }}
              className="dark__text-gray-100 dark__bg-gray-900"
            >
              <Badge className="fs-9" bg="secondary">
                + Add New Item
              </Badge>
            </p>
          </td>
          <td colSpan={7}></td>
          <td style={{ textAlign: 'right' }}></td>
        </tr>
      </tfoot>
    </Table>
  )
}

export default OptionalItemsTab
