import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/base/Button'
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup'
import FilterTab, { FilterTabItem } from 'components/common/FilterTab'
import PageBreadcrumb from 'components/common/PageBreadcrumb'
import SearchBox from 'components/common/SearchBox'
import { customersTablecolumns } from 'components/tables/CustomersTable'
import { defaultBreadcrumbItems } from 'data/commonData'
import { customers } from 'data/e-commerce/customers'
import useAdvanceTable from 'hooks/useAdvanceTable'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import { ChangeEvent } from 'react'

import ClientsTable from './components/ClientsTable'
import { useState } from 'react'

import ClientAddBody from './components/ClientAddBody'
import Model from 'Model'
import { useSpring, animated } from '@react-spring/web'

import 'react-loading-skeleton/dist/skeleton.css'

const tabItems: FilterTabItem[] = [
  {
    label: 'Total Customers',
    value: 'total-coustomers',
    count: 68817
  },
  {
    label: 'Active Customers',
    value: 'active-coustomers',
    count: 6
  },
  {
    label: 'Incative Customers',
    value: 'incative-coustomers',
    count: 17
  },
  {
    label: 'Active Contacts',
    value: 'active-contacts',
    count: 6810
  },
  {
    label: 'Intactive Customers',
    value: 'intactive-coustomers',
    count: 8
  },
  {
    label: 'Contact Logged In Today',
    value: 'contact-logged-in-today',
    count: 2
  }
]

const filterMenus: FilterMenu[] = [
  {
    label: 'Country',
    items: [
      {
        label: 'USA'
      },
      {
        label: 'UK'
      },
      {
        label: 'Australia'
      }
    ]
  },
  {
    label: 'Group',
    items: [
      {
        label: 'Group 1'
      },
      {
        label: 'Group 2'
      },
      {
        label: 'Group 3'
      }
    ]
  }
]

const Customers = () => {
  const table = useAdvanceTable({
    data: customers,
    columns: customersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  })

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined)
  }

  const [modelOpen, setModelOpen] = useState(false)
  const [updateTable, setUpdateTable] = useState(false)

  const [propsOpacity] = useSpring(
    () => ({
      from: { opacity: 0, transform: 'translate3d(0%, -10%, 0)' },
      to: { opacity: 1, transform: 'translate3d(0%, 0, 0)', color: 'blue' }
      // config: { delay: 100 }
    }),
    []
  )

  return (
    <div>
      <animated.div style={propsOpacity}>
        <PageBreadcrumb items={defaultBreadcrumbItems} />
        <div className="mb-9">
          <h2 className="mb-4">Customers</h2>
          <FilterTab tabItems={tabItems} className="mb-2" />

          <AdvanceTableProvider {...table}>
            <div className="mb-4">
              <div className="d-flex flex-wrap gap-3">
                <div className="d-flex">
                  <Button
                    variant="primary"
                    onClick={() => setModelOpen(true)}
                    className="me-2"
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add customer
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setModelOpen(true)}
                  >
                    <FontAwesomeIcon
                      icon={faFileExport}
                      className="fs-9 me-2"
                    />
                    Import
                  </Button>
                </div>
                <div className="scrollbar overflow-hidden-y">
                  <FilterButtonGroup menus={filterMenus} />
                </div>
                <div className="ms-xxl-auto">
                  <div className="d-flex flex-wrap gap-3">
                    <div className="d-flex">
                      <SearchBox
                        placeholder="Search customers"
                        onChange={handleSearchInputChange}
                        className="text-right"
                      />
                      <Button
                        variant="secondary"
                        onClick={() => setModelOpen(true)}
                        className="ms-2"
                      >
                        <FontAwesomeIcon
                          icon={faFileExport}
                          className="fs-9 me-2"
                        />
                        Export
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
              <ClientsTable
                updateTable={updateTable}
                setUpdateTable={() => setUpdateTable(!modelOpen)}
              />
            </div>
            {modelOpen && (
              <Model
                show={modelOpen}
                setClose={() => setModelOpen(false)}
                size="lg"
                noFooter={true}
                body={
                  <ClientAddBody
                    modelClose={() => setModelOpen(false)}
                    setUpdateTable={() => setUpdateTable(!modelOpen)}
                  />
                }
              />
            )}
          </AdvanceTableProvider>
        </div>
      </animated.div>
    </div>
  )
}

export default Customers
