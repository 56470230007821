import { ColumnDef } from '@tanstack/react-table'
import AdvanceTable from 'components/base/AdvanceTable'
import useAdvanceTable from 'hooks/useAdvanceTable'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import AdvanceTableFooter from 'components/base/AdvanceTableFooter'

import { Customer, ClientContact } from '../../types'

import { useEffect, useState } from 'react'

import apiRequest from 'apiRequest'

const columns: ColumnDef<ClientContact>[] = [
  {
    accessorKey: 'id',
    header: 'Id',
    meta: {
      headerProps: { style: { width: '15%', minWidth: 140 } },
      cellProps: { className: 'py-2' }
    },
    cell: ({ row: { index } }) => {
      return <p className="mb-0">{index + 1}</p>
    }
  },
  {
    accessorKey: 'first_name',
    header: 'First Name',
    meta: {
      headerProps: { style: { width: '15%', minWidth: 140 } },
      cellProps: { className: 'py-2' }
    }
  },
  {
    accessorKey: 'last_name',
    header: 'Last Name',
    meta: {
      headerProps: { style: { width: '15%', minWidth: 140 } },
      cellProps: { className: 'py-2' }
    }
  },
  {
    accessorKey: 'email',
    header: 'Email',
    meta: {
      headerProps: { style: { width: '15%', minWidth: 140 } },
      cellProps: { className: 'py-2' }
    }
  },
  {
    accessorKey: 'phone',
    header: 'Phone',
    meta: {
      headerProps: { style: { width: '15%', minWidth: 140 } },
      cellProps: { className: 'py-2' }
    }
  }
]

const ClientContacts = (props: {
  client: Customer
  preload: boolean
  setRefresh: (e: boolean) => void
  refresh: boolean
  currentKey: string
}) => {
  const [ClientContacts, setClientContacts] = useState<ClientContact[]>([])
  const [refreshComponent, setRefreshComponent] = useState(false)

  useEffect(() => {
    if (props.currentKey == 'contacts') {
      setRefreshComponent(true)
    } else {
      setRefreshComponent(false)
    }
  }, [props.currentKey])

  useEffect(() => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/client/contacts/get',
      true
    )
    API_REQUEST.send(
      { client: props.client.id },
      (data: { status: string; data: ClientContact[] }) => {
        if (data.status == 'success') {
          setClientContacts(data.data)
        }
      }
    )
  }, [refreshComponent])

  const table = useAdvanceTable({
    data: ClientContacts,
    columns,
    pageSize: 6,
    pagination: true,
    sortable: true
  })

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="border-y border-translucent">
          <AdvanceTable
            tableProps={{ size: 'sm', className: 'phoenix-table fs-9' }}
          />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  )
}

export default ClientContacts
