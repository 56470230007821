import ReactSelect from 'components/base/ReactSelect'
import { Link } from 'react-router-dom'

import { useState } from 'react'

import { Variant, SelectType } from 'modules/inventory/types'

import apiRequest from 'apiRequest'

const itemVariant = ({
  className,
  ...props
}: {
  className?: string
  variant: Variant
  variants: Variant[]
  variantSelectOptions: SelectType[]
  setVariants: (value: Variant[]) => void
  key: number
}) => {
  const [variantTypeValues, setVariantTypeValues] = useState<SelectType[]>([])

  const [variantTypesSelectOpen, setVariantTypesSelectOpen] =
    useState<boolean>(false)
  const [variantTypeValuesSelectOpen, setVariantTypeValuesSelectOpen] =
    useState<boolean>(false)

  // eslint-disable-next-line
  const getVariantValues = (e: any) => {
    if (e?.value === '') return

    props.variant.id = e.value
    props.variant.label = e.label

    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/items/variants/values/search',
      true
    )
    API_REQUEST.send(
      { type: 'select', variant: e?.value },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setVariantTypeValues(data.data)
        }
      }
    )
  }

  return (
    <div className={className}>
      <div className="d-flex gap-2 mb-2">
        <h5 className="mb-0 text-body-highlight">
          Option {props.variants.indexOf(props.variant) + 1}
        </h5>
        <Link
          className="fw-bold fs-9"
          to="#!"
          onClick={() => {
            props.variants.splice(props.variants.indexOf(props.variant), 1)
            props.setVariants([...props.variants])
          }}
        >
          Remove
        </Link>
      </div>
      <ReactSelect
        className="mb-3"
        options={props.variantSelectOptions}
        onChange={e => {
          getVariantValues(e)
          setVariantTypesSelectOpen(false)
        }}
        onBlur={() => setVariantTypesSelectOpen(false)}
        onMenuOpen={() => setVariantTypesSelectOpen(true)}
        menuIsOpen={variantTypesSelectOpen}
        placeholder="Select Variant"
      />
      <ReactSelect
        options={variantTypeValues}
        onBlur={() => setVariantTypeValuesSelectOpen(false)}
        onMenuOpen={() => setVariantTypeValuesSelectOpen(true)}
        menuIsOpen={variantTypeValuesSelectOpen}
        onChange={e => {
          props.variant.data = e
          props.setVariants([...props.variants])
        }}
        isMulti
        placeholder="Select Variant Values"
      />
    </div>
  )
}

export default itemVariant
