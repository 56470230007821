import React from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { useState } from 'react'
import { ClientBillingShipping } from '../types'

const ClientBillingAndShipping = (props: {
  setClientBillingAddress: () => void
  setClientShippingAddress: () => void
  clientBillingAddress: string[]
  clientShippingAddress: string[]
}) => {
  console.log(props)

  const [billingAddress, setBillingAddress] = useState<ClientBillingShipping[]>(
    []
  )
  const [shippingAddress, setShippingAddress] = useState<
    ClientBillingShipping[]
  >([])

  const removeShippingAddress = (index: number) => {
    setShippingAddress(shippingAddress.splice(index, 1))
  }

  const removeBillingAddress = (index: number) => {
    setBillingAddress(billingAddress.splice(index, 1))
  }

  return (
    <Row>
      <Col xs={12} lg={6}>
        <Row>
          <Col xs={12} md={2} sm={2} lg={2}>
            <Button
              className="btn-sm"
              onClick={() => {
                setBillingAddress([
                  ...billingAddress,
                  {
                    address: '',
                    city: '',
                    state: '',
                    zip_code: '',
                    street: '',
                    country: ''
                  }
                ])
              }}
            >
              +
            </Button>
          </Col>
          <Col xs={12} md={10} sm={10} lg={10}>
            <h5 className="text-body-highlight mb-2 pb-1 pt-1">
              Billing Address
            </h5>
            {billingAddress.map((item, index) => (
              <Row
                key={'b' + index}
                style={{
                  borderBottom: '1px solid #000',
                  borderBottomStyle: 'dotted',
                  paddingBottom: '10px'
                }}
              >
                <Form.Control
                  key={index}
                  type="text"
                  placeholder="Address"
                  style={{ marginTop: '5px' }}
                />
                <Form.Control
                  key={index}
                  id="state"
                  type="text"
                  placeholder="State"
                  style={{ marginTop: '5px' }}
                />
                <Form.Control
                  key={index}
                  id="state"
                  type="text"
                  placeholder="City"
                  style={{ marginTop: '5px' }}
                />
                <Form.Control
                  key={index}
                  id="state"
                  type="text"
                  placeholder="Zip Code"
                  style={{ marginTop: '5px' }}
                />
                <Row>
                  <Col xs={12} md={10} lg={10} sm={10}>
                    <Form.Control
                      key={index}
                      id="state"
                      type="text"
                      placeholder="Country"
                      style={{ marginTop: '5px' }}
                      className="w-100"
                    />
                  </Col>
                  <Col xs={12} md={2} lg={2} sm={2}>
                    <Button
                      variant="danger"
                      className="btn-sm mt-1"
                      onClick={() => removeBillingAddress(index)}
                    >
                      X
                    </Button>
                  </Col>
                </Row>
              </Row>
            ))}
          </Col>
        </Row>
      </Col>
      <Col xs={12} lg={6}>
        <Row>
          <Col xs={12} md={2} sm={2} lg={2}>
            <Button
              className="btn-sm"
              onClick={() =>
                setShippingAddress([
                  ...shippingAddress,
                  {
                    address: '',
                    city: '',
                    state: '',
                    zip_code: '',
                    street: '',
                    country: ''
                  }
                ])
              }
            >
              +
            </Button>
          </Col>
          <Col xs={12} md={10} sm={10} lg={10}>
            <h5 className="text-body-highlight mb-2 pb-1 pt-1">
              Shipping Address
            </h5>

            {shippingAddress.map((item, index) => (
              <Row
                key={'s' + index}
                style={{
                  borderBottom: '1px solid #000',
                  borderBottomStyle: 'dotted',
                  paddingBottom: '10px'
                }}
              >
                <Form.Control
                  key={index}
                  type="text"
                  placeholder="Address"
                  style={{ marginTop: '5px' }}
                />
                <Form.Control
                  key={index}
                  id="state"
                  type="text"
                  placeholder="State"
                  style={{ marginTop: '5px' }}
                />
                <Form.Control
                  key={index}
                  id="state"
                  type="text"
                  placeholder="City"
                  style={{ marginTop: '5px' }}
                />
                <Form.Control
                  key={index}
                  id="state"
                  type="text"
                  placeholder="Zip Code"
                  style={{ marginTop: '5px' }}
                />
                <Row>
                  <Col xs={12} md={10} lg={10} sm={10}>
                    <Form.Control
                      key={index}
                      id="state"
                      type="text"
                      placeholder="Country"
                      style={{ marginTop: '5px' }}
                    />
                  </Col>
                  <Col xs={12} md={2} lg={2} sm={2}>
                    <Button
                      variant="danger"
                      className="btn-sm mt-1"
                      onClick={() => removeShippingAddress(index)}
                    >
                      X
                    </Button>
                  </Col>
                </Row>
              </Row>
            ))}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ClientBillingAndShipping
