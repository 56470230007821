import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/base/Button'
import Section from 'components/base/Section'
import { Badge, Col, Row } from 'react-bootstrap'
import phoenixMart from 'assets/img/logos/phoenix-mart.png'
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { toast } from 'react-toastify'

import InvoiceItemsTable from './invoice_items_table'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DB_Invoice } from 'modules/sales/Types'

import apiRequest from 'apiRequest'

const Invoice = (props: {
  invoiceid: string
  refreshPreview: boolean
  showPreview: (e: boolean) => void
  setInvoiceId: (e: string) => void
}) => {
  const navigate = useNavigate()

  const [invoice, setInvoice] = useState<DB_Invoice | null>(null)
  const [preload, setPreload] = useState(true)

  useEffect(() => {
    setPreload(true)
  }, [props.refreshPreview])

  useEffect(() => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/sales/invoices/get',
      true
    )
    API_REQUEST.send(
      { invoice: props.invoiceid },
      (data: { status: string; message: string; data: DB_Invoice }) => {
        if (data.status == 'success') {
          setInvoice(data.data)
        } else {
          toast('Invoice not found', {
            position: 'top-right',
            type: 'error',
            isLoading: false,
            theme: 'light',
            className: 'text-sm'
          })

          setInvoice(null)
          props.setInvoiceId('')
          props.showPreview(false)

          navigate('/module/sales/invoices', { replace: true, state: true })
        }
        setPreload(false)
      }
    )
  }, [props.invoiceid, props.showPreview, props.setInvoiceId])

  return preload ? (
    <>
      <div className="pt-4 pb-8 p-2 bg-body-emphasis dark__bg-gray-1200 border-top">
        <SkeletonTheme highlightColor="#444">
          <Row
            className="g-4"
            style={{
              opacity: 0.2,
              alignItems: 'right'
            }}
          >
            <Col xs={12} lg={6} md={6} sm={6} xsl={12}>
              <Skeleton count={4} duration={2} />
            </Col>
            <Col xs={12} lg={6} md={6} sm={6} xsl={12}>
              <Skeleton count={4} duration={2} />
            </Col>
          </Row>
          <Skeleton
            count={6}
            style={{ opacity: 0.2, marginTop: '15px' }}
            duration={2}
          />
        </SkeletonTheme>
      </div>
    </>
  ) : (
    <div className="pt-5 pb-9 bg-body-emphasis dark__bg-gray-1200 border-top">
      <Section className="py-0">
        <div className="d-flex gap-2 justify-content-between align-items-end mb-4">
          <h2 className="mb-0 flex-1">
            Invoice{' '}
            <Badge
              style={{ fontSize: '12px' }}
              bg={invoice?.status == 0 ? 'danger' : 'primary'}
            >
              #{invoice?.invoice_number}
            </Badge>{' '}
          </h2>
          <Button variant="phoenix-secondary">
            <FontAwesomeIcon icon={faDownload} className="me-sm-2" />
            <span className="d-none d-sm-inline-block">Download Invoice</span>
          </Button>
          <Button variant="phoenix-secondary">
            <FontAwesomeIcon icon={faPrint} className="me-sm-2" />
            <span className="d-none d-sm-inline-block">Print</span>
          </Button>
        </div>

        <div className="bg-body dark__bg-gray-1100 p-4 mb-4 rounded-2">
          <Row className="g-4">
            <Col xs={12} lg={6}>
              <Row className="g-4 g-lg-2">
                <Col xs={12} sm={6} lg={12}>
                  <Row className=" align-items-center g-0">
                    <Col xs="auto" lg={6} xl={5}>
                      <h6 className="me-3">Customer :</h6>
                    </Col>
                    <Col xs="auto" lg={6} xl={7}>
                      <p className="fs-9 text-body-secondary fw-semibold mb-0">
                        {invoice?.client.name}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} sm={6} lg={12}>
                  <Row className=" align-items-center g-0">
                    <Col xs="auto" lg={6} xl={5}>
                      <h6 className="mb-0 me-3">Invoice No : </h6>
                    </Col>
                    <Col xs="auto" lg={6} xl={7}>
                      <p className="fs-9 text-body-secondary fw-semibold mb-0">
                        {invoice?.invoice_number}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} sm={6} lg={12}>
                  <Row className=" align-items-center g-0">
                    <Col xs="auto" lg={6} xl={5}>
                      <h6 className="me-3">Invoice Date :</h6>
                    </Col>
                    <Col xs="auto" lg={6} xl={7}>
                      <p className="fs-9 text-body-secondary fw-semibold mb-0">
                        {invoice?.invoice_date}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} sm={6} lg={12}>
                  <Row className=" align-items-center g-0">
                    <Col xs="auto" lg={6} xl={5}>
                      <h6 className="me-3">Due Date :</h6>
                    </Col>
                    <Col xs="auto" lg={6} xl={7}>
                      <p className="fs-9 text-body-secondary fw-semibold mb-0 text-danger">
                        {invoice?.due_date}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} sm={6} lg={12}>
                  <Row className=" align-items-center g-0">
                    <Col xs="auto" lg={6} xl={5}>
                      <h6 className="me-3">Currency :</h6>
                    </Col>
                    <Col xs="auto" lg={6} xl={7}>
                      <p className="fs-9 text-body-secondary fw-semibold mb-0">
                        {invoice?.currency.name}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={6} lg={6}>
              <Row className="g-4">
                <Col xs={12} lg={6}>
                  <h6 className="mb-2"> Billing Address :</h6>
                  <div className="fs-9 text-body-secondary fw-semibold mb-0">
                    <p className="mb-2">{invoice?.primary_contact?.name},</p>
                    {invoice?.primary_contact?.billing_address}
                    {invoice?.primary_contact?.phonenumber}
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <h6 className="mb-2"> Shipping Address :</h6>
                  <div className="fs-9 text-body-secondary fw-semibold mb-0">
                    <p className="mb-2">{invoice?.primary_contact?.name},</p>
                    {invoice?.primary_contact?.shipping_address}
                    {invoice?.primary_contact?.phonenumber}
                  </div>
                </Col>
                {invoice?.note != null && (
                  <Col xs={12} lg={12}>
                    <hr />
                    <h6 className="mb-2"> Admin Note :</h6>
                    <div className="fs-9 text-body-secondary fw-semibold mb-0">
                      <p className="mb-2">{invoice?.note}</p>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div>

        <InvoiceItemsTable invoice={invoice ? invoice : null} />

        <Row className="g-4  border-bottom">
          <Col xs={12} lg={6}>
            {invoice?.client_note != null && (
              <Col xs={12} lg={12}>
                <hr />
                <h6 className="mb-2"> Client Note :</h6>
                <div className="fs-9 text-body-secondary fw-semibold mb-0">
                  <p className="mb-2">{invoice?.client_note}</p>
                </div>
              </Col>
            )}
            {invoice?.terms != null && (
              <Col xs={12} lg={12} className="mt-3">
                <hr />
                <h6 className="mb-2"> Terms and Conditions :</h6>
                <div className="fs-9 text-body-secondary fw-semibold mb-0">
                  <p className="mb-2">{invoice?.terms}</p>
                </div>
              </Col>
            )}
          </Col>
          <Col xs={12} lg={6}>
            <div className="text-end py-9">
              <img className="mb-3" src={phoenixMart} alt="phoenix-mart" />
              <h4>Authorized Signatory</h4>
            </div>
          </Col>
        </Row>
      </Section>
    </div>
  )
}

export default Invoice
