import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AvatarUpload from 'components/common/AvatarUpload'
import { FallingLines } from 'react-loader-spinner'
import { Card, Col, Row } from 'react-bootstrap'
import team15 from 'assets/img/team/15.webp'
import {
  faFacebook,
  faLinkedin,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'

import { useSpring, animated } from '@react-spring/web'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export type Customer = {
  id: string
  name: string
  vat_number: string
  phone: string
  currency: string
  address: string
  last_login: string
  primary_email?: string
  default_language: string
  last_order?: string
}

const EcomProfileCard = (props: { client: Customer; preload: boolean }) => {
  const [propsOpacity] = useSpring(
    () => ({
      from: { opacity: 0, transform: 'translate3d(100%, 0, 0)' },
      to: { opacity: 1, transform: 'translate3d(0%, 0, 0)', color: 'blue' },
      config: { delay: 100 }
    }),
    []
  )

  const [propsOpacity2] = useSpring(
    () => ({
      from: { opacity: 0, transform: 'translate3d(0, 100%, 0)' },
      to: { opacity: 1, transform: 'translate3d(0%, 0%, 0)' },
      config: { duration: 500, delay: 1000 }
    }),
    []
  )

  return (
    <Card className="h-100">
      <Card.Body>
        {props.preload ? (
          <>
            <SkeletonTheme highlightColor="#444">
              <Skeleton
                count={1}
                circle={true}
                height={100}
                width={100}
                style={{ opacity: 0.2, marginBottom: '10px' }}
                duration={2}
              />
              <Skeleton
                count={5}
                style={{ opacity: 0.2, marginTop: '10px' }}
                duration={2}
              />
            </SkeletonTheme>
          </>
        ) : (
          <>
            <animated.div style={propsOpacity}>
              <div className="border-bottom border-dashed pb-4">
                <Row className="align-items-center g-3 g-sm-5 text-center text-sm-start">
                  <Col xs={12} sm="auto">
                    <AvatarUpload size="5xl" src={team15} />
                  </Col>
                  <Col xs={12} sm={12} lg={4} md={4} className="">
                    {props.preload ? (
                      <FallingLines color="#4fa94d" width="30" visible={true} />
                    ) : (
                      <>
                        <h4 className="mb-0 lh-1">{props.client.name}</h4>
                        <p className="text-body-secondary">
                          {props.client.address}
                        </p>
                      </>
                    )}
                    <p className="text-body-secondary">Joined 3 months ago</p>
                    <div>
                      <a href="#!" className="me-2">
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          className="text-body-quaternary text-opacity-75 text-primary-hover"
                        />
                      </a>
                      <a href="#!" className="me-2">
                        <FontAwesomeIcon
                          icon={faFacebook}
                          className="text-body-quaternary text-opacity-75 text-primary-hover"
                        />
                      </a>
                      <a href="#!">
                        <FontAwesomeIcon
                          icon={faTwitter}
                          className="text-body-quaternary text-opacity-75 text-primary-hover"
                        />
                      </a>
                    </div>
                  </Col>
                  <Col
                    xs={8}
                    sm={12}
                    lg="auto"
                    md="auto"
                    className="flex-1 border-left border-dashed"
                  >
                    <>
                      <div>
                        <div className="d-flex justify-content-between gap-2 mb-3">
                          <h5 className="text-body-highlight mb-0">Email</h5>
                          <a
                            className="lh-1"
                            href={'mailto:' + props.client.primary_email}
                          >
                            {props.preload ? (
                              <FallingLines
                                color="#4fa94d"
                                width="30"
                                visible={true}
                              />
                            ) : (
                              <h4 className="mb-0 lh-1">
                                {props.client.primary_email}
                              </h4>
                            )}
                          </a>
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-2">
                          <h5 className="text-body-highlight mb-0">Phone</h5>
                          <a href={'tel:' + props.client.phone}>
                            {props.client.phone}
                          </a>
                        </div>
                      </div>
                    </>
                  </Col>
                </Row>
              </div>
            </animated.div>
            <animated.div style={propsOpacity2}>
              <div className="d-flex flex-between-center pt-4">
                <div>
                  <h6 className="mb-2 text-body-secondary">Total Overdue</h6>
                  <h4 className="fs-7 text-body-highlight mb-0">N/A</h4>
                </div>
                <div className="text-end">
                  <h6 className="mb-2 text-body-secondary">
                    Total Transections
                  </h6>
                  <h4 className="fs-7 text-body-highlight mb-0">N/A</h4>
                </div>
                <div className="text-end">
                  <h6 className="mb-2 text-body-secondary">Last Login</h6>
                  <h4 className="fs-7 text-body-highlight mb-0">
                    {props.client.last_login}
                  </h4>
                </div>
              </div>
            </animated.div>
          </>
        )}
      </Card.Body>
    </Card>
  )
}

export default EcomProfileCard
