import Scrollbar from 'components/base/Scrollbar'
import { DB_Invoice } from 'modules/sales/Types'
import React from 'react'

const InvoiceItemsTable = (props: { invoice: DB_Invoice | null }) => {
  return (
    <Scrollbar autoHeight autoHeightMax="100%">
      <table className="table fs-9 text-body mb-0">
        <thead className="bg-body-secondary">
          <tr>
            <th colSpan={3} scope="col" style={{ width: 5 }}>
              #
            </th>
            <th scope="col" style={{ minWidth: 360 }}>
              Item
            </th>
            <th className="ps-5" scope="col" style={{ minWidth: 150 }}>
              Item Qty
            </th>
            <th scope="col" style={{ minWidth: 80 }}>
              Free Qty
            </th>
            <th className="text-end" scope="col" style={{ width: 80 }}>
              Rate
            </th>
            <th className="text-end" scope="col" style={{ width: 100 }}>
              Discount
            </th>
            <th className="text-end" scope="col" style={{ minWidth: 138 }}>
              Exp. Date
            </th>
            <th className="text-end" scope="col" style={{ minWidth: 100 }}>
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {props.invoice?.items
            ? props.invoice.items.map((item, index) => (
                <tr key={index}>
                  <td colSpan={3} className="align-middle">
                    {index + 1}
                  </td>
                  <td className="align-middle">
                    <p className="line-clamp-1 mb-0 fw-semibold">
                      {item.item_name + ' | '}
                      <small className="text-body-secondary">
                        {item.description}
                      </small>
                    </p>
                  </td>
                  <td className="align-middle ps-5">{item.quantity}</td>
                  <td className="align-middle text-body-tertiary fw-semibold">
                    {item.free_quantity}
                  </td>
                  <td className="align-middle text-end text-body-highlight fw-semibold">
                    {item.rate}
                  </td>
                  <td className="align-middle text-end fw-semibold">
                    {item.discount_amount +
                      (item.discount_percent && item.discount_percent > 0
                        ? ' (' + item.discount_percent + '%)'
                        : '')}
                  </td>
                  <td className="align-middle text-end fw-semibold">
                    {item.exp_date}
                  </td>
                  <td className="align-middle text-end fw-semibold">
                    {item.total}
                  </td>
                </tr>
              ))
            : ''}
          <tr className="bg-body-secondary">
            <td className="align-middle fw-semibold text-end" colSpan={9}>
              Subtotal | <small>{props.invoice?.currency?.name}</small>
            </td>
            <td className="align-middle text-end fw-bold">
              {props.invoice?.sub_total}
            </td>
          </tr>
          {props.invoice?.discount_amount &&
          props.invoice?.discount_amount > 0 ? (
            <tr>
              <td className="fw-bold text-end" colSpan={9}>
                Discount{' '}
                {props.invoice?.discount_percent &&
                props.invoice?.discount_percent > 0
                  ? '(' + props.invoice?.discount_percent + '%)'
                  : null}
              </td>
              <td
                className="align-middle text-end fw-semibold text-success"
                style={{ paddingRight: 0 }}
              >
                -{props.invoice?.discount_amount}
              </td>
              <td />
            </tr>
          ) : null}
          {props.invoice?.adjustment && props.invoice?.adjustment > 0 ? (
            <tr>
              <td className="fw-bold text-end" colSpan={9}>
                Adjustment
              </td>
              <td
                className="align-middle text-end fw-semibold text-danger"
                style={{ paddingRight: 0 }}
              >
                +{props.invoice?.adjustment}
              </td>
              <td />
            </tr>
          ) : null}
          <tr className="bg-body-secondary">
            <td
              className="align-middle ps-4 fw-bold text-body-highlight text-end"
              colSpan={9}
            >
              Grand Total | <small>{props.invoice?.currency?.name}</small>
            </td>
            <td className="align-middle text-end fw-bold">
              {props.invoice?.total}
            </td>
          </tr>
        </tbody>
      </table>
    </Scrollbar>
  )
}

export default InvoiceItemsTable
