import { Card, Col, Row, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { useState, useEffect } from 'react'

import apiRequest from 'apiRequest'

import { SelectType } from 'modules/inventory/types'
import ReactSelect from 'react-select'

const itemRightTop = ({
  className,
  productGroup,
  productVendor,
  productTag,
  productSubGroup,
  productType,
  setProductGroup,
  setProductVendor,
  setProductTag,
  setProductSubGroup,
  setProductType
}: {
  className?: string
  productGroup: string
  productVendor: string
  productTag: SelectType[]
  productSubGroup: string[]
  productType: string
  setProductGroup: (value: string) => void
  setProductVendor: (value: string) => void
  setProductTag: (value: SelectType[]) => void
  setProductSubGroup: (value: string[]) => void
  setProductType: (value: string) => void
}) => {
  const [groups, setGroups] = useState<SelectType[]>([])
  const [vendors, setVendors] = useState<SelectType[]>([])
  const [tags, setTags] = useState<SelectType[]>([])
  const [subGroups, setSubGroups] = useState<SelectType[]>([])
  const [productTypes, setProductTypes] = useState<SelectType[]>([])

  const [groupSelectOpen, setGroupSelectOpen] = useState<boolean>(false)
  const [vendorSelectOpen, setVendorSelectOpen] = useState<boolean>(false)
  const [tagSelectOpen, setTagSelectOpen] = useState<boolean>(false)
  const [subGroupSelectOpen, setSubGroupSelectOpen] = useState<boolean>(false)
  const [productTypeOpen, setProductTypeOpen] = useState<boolean>(false)

  console.log(productTag)
  console.log(productSubGroup)

  const searchGroups = (keyword: string) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/items/commodity-groups/search',
      true
    )

    API_REQUEST.send(
      { keyword: keyword, type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setGroups(data.data)
        }
      }
    )
  }

  const searchVendors = (keyword: string) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/purchase/vendors/search',
      true
    )

    API_REQUEST.send(
      { keyword: keyword, type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setVendors(data.data)
        }
      }
    )
  }

  const searchTags = (keyword: string) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/tags/search',
      true
    )
    API_REQUEST.send(
      { keyword: keyword, type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setTags(data.data)
        }
      }
    )
  }

  const searchSubGroups = (keyword: string) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/items/sub-groups/search',
      true
    )
    API_REQUEST.send(
      { keyword: keyword, type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setSubGroups(data.data)
        }
      }
    )
  }

  useEffect(() => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/items/types/search',
      true
    )
    API_REQUEST.send(
      { type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setProductTypes(data.data)
        }
      }
    )
  }, [])

  // eslint-disable-next-line
  const handleProductTypeChange = (selected: any) => {
    setProductType(selected.value != undefined ? selected.value : '')
    setProductTypeOpen(false)
  }

  // eslint-disable-next-line
  const handleGroupChange = (selected: any) => {
    setProductGroup(selected.value != undefined ? selected.value : '')
    setGroupSelectOpen(false)
  }

  // eslint-disable-next-line
  const handleVendorChange = (selected: any) => {
    setProductVendor(selected.value != undefined ? selected.value : '')
    setVendorSelectOpen(false)
  }

  // eslint-disable-next-line
  const handleTagChange = (selected: any) => {
    setProductTag(selected)
    setTagSelectOpen(false)
  }

  // eslint-disable-next-line
  const handleSubGroupChange = (selected: any) => {
    setProductSubGroup(selected.value != undefined ? selected.value : [])
    setSubGroupSelectOpen(false)
  }

  return (
    <Card className={className}>
      <Card.Body>
        <h4 className="mb-4">Product Details</h4>
        <Row className="gx-3 gy-4">
          <Col xs={12} sm={6} xl={12}>
            <Form.Group>
              <Form.Label className="fs-8 text-start p-0 text-capitalize">
                Product Type
              </Form.Label>
              <ReactSelect
                options={productTypes}
                placeholder="Select item type..."
                className="small"
                classNamePrefix="react-select"
                onChange={handleProductTypeChange}
                onMenuOpen={() => setProductTypeOpen(true)}
                onBlur={() => setProductTypeOpen(false)}
                menuIsOpen={productTypeOpen}
                value={
                  productType
                    ? {
                        value: productType,
                        label: productTypes.find(
                          type => type.value == productType
                        )?.label
                      }
                    : null
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} xl={12}>
            <div className="d-flex gap-2 mb-2">
              <h5 className="mb-0 text-body-highlight">Commodity Group</h5>
              <Link className="fw-bold fs-9" to="#!">
                Add new group
              </Link>
            </div>
            <ReactSelect
              options={groups}
              placeholder="Select group type..."
              className="small"
              classNamePrefix="react-select"
              onInputChange={searchGroups}
              onChange={handleGroupChange}
              onMenuOpen={() => setGroupSelectOpen(true)}
              onBlur={() => setGroupSelectOpen(false)}
              menuIsOpen={groupSelectOpen}
              value={
                productGroup
                  ? {
                      value: productGroup,
                      label: groups.find(group => group.value == productGroup)
                        ?.label
                    }
                  : null
              }
            />
          </Col>
          <Col xs={12} sm={6} xl={12}>
            <div className="d-flex gap-2 mb-2">
              <h5 className="mb-0 text-body-highlight">Sub Group</h5>
              <Link className="fw-bold fs-9" to="#!">
                Add new sub group
              </Link>
            </div>
            <ReactSelect
              options={subGroups}
              placeholder="Select sub group type..."
              className="small"
              classNamePrefix="react-select"
              onInputChange={searchSubGroups}
              onChange={handleSubGroupChange}
              onMenuOpen={() => setSubGroupSelectOpen(true)}
              onBlur={() => setSubGroupSelectOpen(false)}
              menuIsOpen={subGroupSelectOpen}
            />
          </Col>
          <Col xs={12} sm={6} xl={12}>
            <div className="d-flex gap-2 mb-2">
              <h5 className="mb-0 text-body-highlight">Vendor</h5>
              <Link className="fw-bold fs-9" to="#!">
                Add new vendor
              </Link>
            </div>
            <ReactSelect
              options={vendors}
              placeholder="Select vendor type..."
              className="small"
              classNamePrefix="react-select"
              onInputChange={searchVendors}
              onChange={handleVendorChange}
              onMenuOpen={() => setVendorSelectOpen(true)}
              onBlur={() => setVendorSelectOpen(false)}
              menuIsOpen={vendorSelectOpen}
              value={
                productVendor
                  ? {
                      value: productGroup,
                      label: vendors.find(group => group.value == productVendor)
                        ?.label
                    }
                  : null
              }
            />
          </Col>
          <Col xs={12} sm={6} xl={12}>
            <div className="d-flex gap-2 mb-2">
              <h5 className="mb-0 text-body-highlight">Tags</h5>
            </div>
            <ReactSelect
              options={tags}
              placeholder="Select tags..."
              className="small"
              classNamePrefix="react-select"
              onInputChange={searchTags}
              onChange={handleTagChange}
              onMenuOpen={() => setTagSelectOpen(true)}
              onBlur={() => setTagSelectOpen(false)}
              menuIsOpen={tagSelectOpen}
              isMulti
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default itemRightTop
