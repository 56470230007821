import { Row } from 'react-bootstrap'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAccessibleIcon } from '@fortawesome/free-brands-svg-icons'
import { faAddressBook } from '@fortawesome/free-regular-svg-icons'
import {
  faDollar,
  faHeart,
  faMap,
  faShoppingCart,
  faStar
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import EcomProfileOrdersTable from 'components/tables/EcomProfileOrdersTable'
import EcomWishlistTable from 'components/tables/EcomWishlistTable'
import { Nav, Tab } from 'react-bootstrap'

import { useState } from 'react'
import { Customer } from '../types'

import ProfileContent from './Tabs/ProfileContent'
import ClientContacts from './Tabs/ClientContacts'

import Badge from 'components/base/Badge'

interface TabLink {
  id: string
  label: string
  number?: number
  icon: IconProp
  content?: JSX.Element
}

const sales_tabs: TabLink[] = [
  {
    id: 'proposals',
    label: 'Proposals',
    icon: faDollar,
    content: <EcomProfileOrdersTable />
  },
  {
    id: 'estimates',
    label: 'Estimate',
    icon: faStar,
    content: <EcomWishlistTable />
  },
  {
    id: 'invoices',
    label: 'Invoices',
    icon: faHeart,
    content: <EcomProfileOrdersTable />
  },
  {
    id: 'performa-invoice',
    label: 'Performa Invoice',
    icon: faAccessibleIcon,
    content: <EcomProfileOrdersTable />
  },
  {
    id: 'credit-notes',
    label: 'Credit Notes',
    icon: faAddressBook,
    content: <EcomProfileOrdersTable />
  }
]

const ProfileDetailsTab = (props: {
  client: Customer
  preload: boolean
  setRefresh: (e: boolean) => void
  refresh: boolean
}) => {
  const [key, setKey] = useState('profile')
  const [activeTab, setActiveTab] = useState('profile')

  const tabLinks: TabLink[] = [
    {
      id: 'profile',
      label: 'Profile',
      icon: faShoppingCart,
      content: (
        <ProfileContent
          client={props.client}
          preload={props.preload}
          setRefresh={props.setRefresh}
          refresh={props.refresh}
        />
      )
    },
    {
      id: 'contacts',
      label: 'Contacts',
      icon: faStar,
      content: (
        <ClientContacts
          client={props.client}
          preload={props.preload}
          setRefresh={props.setRefresh}
          refresh={props.refresh}
          currentKey={key}
        />
      )
    },
    {
      id: 'map',
      label: 'Map',
      icon: faMap,
      content: <EcomWishlistTable />
    }
  ]

  const topNavs: TabLink[] = [
    {
      id: 'profile',
      label: 'Profile',
      icon: faShoppingCart
    },
    {
      id: 'sales',
      label: 'Sales',
      icon: faShoppingCart
    },
    {
      id: 'subscriptions',
      label: 'Subscriptions',
      icon: faShoppingCart
    },
    {
      id: 'expenses',
      label: 'Expenses',
      icon: faShoppingCart
    },
    {
      id: 'contracts',
      label: 'Contracts',
      icon: faShoppingCart
    },
    {
      id: 'projects',
      label: 'Projects',
      icon: faShoppingCart
    },
    {
      id: 'tasks',
      label: 'Tasks',
      icon: faShoppingCart
    },
    {
      id: 'tickets',
      label: 'Tickets',
      icon: faShoppingCart
    },
    {
      id: 'files',
      label: 'Files',
      icon: faShoppingCart
    },
    {
      id: 'vault',
      label: 'Vault',
      icon: faShoppingCart
    },
    {
      id: 'reminders',
      label: 'Reminders',
      icon: faShoppingCart
    },
    {
      id: 'other',
      label: 'Other',
      icon: faShoppingCart
    }
  ]

  return (
    <Tab.Container id="basic-tabs-example" defaultActiveKey="profile">
      <Nav
        variant="underline"
        className="mb-3 pb-1 gap-3 nav-underline flex-nowrap scrollbar"
      >
        {topNavs.map((tab, index) => (
          <Nav.Item key={index} onClick={() => setActiveTab(tab.id)}>
            <Nav.Link eventKey={tab.id}>
              <Badge
                style={{ fontSize: '13px', padding: '5px' }}
                bg={activeTab === tab.id ? 'primary' : 'secondary'}
                variant="phoenix"
              >
                {tab.label}
              </Badge>
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey="sales">
          <Tab.Container defaultActiveKey="proposals">
            <Nav className="mb-3 pb-1 gap-3 nav-underline flex-nowrap scrollbar">
              {sales_tabs.map(item => (
                <Nav.Item key={item.id}>
                  <Nav.Link eventKey={item.id} className="text-nowrap">
                    <FontAwesomeIcon icon={item.icon} className="me-2" />
                    {item.label}{' '}
                    {item.number && (
                      <span className="text-body-tertiary fw-normal">
                        ({item.number})
                      </span>
                    )}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>

            <Tab.Content>
              {sales_tabs.map(item => (
                <Tab.Pane key={item.id} eventKey={item.id}>
                  <Row
                    style={{
                      paddingTop: '1%',
                      paddingBottom: '1%',
                      borderRadius: '10px',
                      backgroundColor: '#fff'
                    }}
                    className="light__bg-light dark__text-gray-100 dark__bg-gray-1100"
                    bg="light"
                  >
                    {item.content}
                  </Row>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </Tab.Pane>
        <Tab.Pane eventKey="profile">
          <Tab.Container defaultActiveKey="profile">
            <Nav className="mb-3 pb-1 gap-3 nav-underline flex-nowrap scrollbar">
              {tabLinks.map(item => (
                <Nav.Item key={item.id} onClick={() => setKey(item.id)}>
                  <Nav.Link eventKey={item.id} className="text-nowrap">
                    <FontAwesomeIcon icon={item.icon} className="me-2" />
                    {item.label}{' '}
                    {item.number && (
                      <span className="text-body-tertiary fw-normal">
                        ({item.number})
                      </span>
                    )}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>

            <Tab.Content>
              {tabLinks.map(item => (
                <Tab.Pane key={item.id} eventKey={item.id}>
                  <Row
                    style={{
                      paddingTop: '1%',
                      paddingBottom: '1%',
                      borderRadius: '10px',
                      backgroundColor: '#fff'
                    }}
                    className="light__bg-light dark__text-gray-100 dark__bg-gray-1100"
                    bg="light"
                  >
                    {item.content}
                  </Row>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </Tab.Pane>
        <Tab.Pane eventKey="subscriptions"></Tab.Pane>
        <Tab.Pane eventKey="expenses"></Tab.Pane>
        <Tab.Pane eventKey="contracts"></Tab.Pane>
        <Tab.Pane eventKey="projects"></Tab.Pane>
        <Tab.Pane eventKey="tasks"></Tab.Pane>
        <Tab.Pane eventKey="tickets"></Tab.Pane>
        <Tab.Pane eventKey="files"></Tab.Pane>
        <Tab.Pane eventKey="vault"></Tab.Pane>
        <Tab.Pane eventKey="reminders"></Tab.Pane>
        <Tab.Pane eventKey="other"></Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  )
}

export default ProfileDetailsTab
