import { UilEnvelope } from '@iconscout/react-unicons'
import { Card, Col, Row } from 'react-bootstrap'
import Unicon from 'components/base/Unicon'

import 'react-loading-skeleton/dist/skeleton.css'

export type Customer = {
  id: string
  name: string
  vat_number: string
  phone: string
  currency: string
  address: string
  last_login: string
  default_language: string
  primary_email?: string
  last_order?: string
}

const EcoimDefaultAddressCard = (props: {
  client: Customer
  preload: boolean
}) => {
  console.log(props)
  return (
    <Card className="h-100">
      <Card.Body>
        <Row className="justify-content-center">
          <Col xs={6} className="mb-3 text-center">
            <>
              <Unicon
                icon={UilEnvelope}
                className={`text-success mb-1`}
                size={31.25}
              />
              <h1 className="fs-5 mt-3">LKR 4,000</h1>
              <p className="fs-9 mb-0">Total Income</p>
            </>
          </Col>
          <Col xs={6} className="mb-3 text-center">
            <>
              <Unicon
                icon={UilEnvelope}
                className={`text-success mb-1`}
                size={31.25}
              />
              <h1 className="fs-5 mt-3">LKR 5,565</h1>
              <p className="fs-9 mb-0">Total Income</p>
            </>
          </Col>
          <Col xs={6} className="mb-3 text-center">
            <>
              <Unicon
                icon={UilEnvelope}
                className={`text-success mb-1`}
                size={31.25}
              />
              <h1 className="fs-5 mt-3">LKR 4,000</h1>
              <p className="fs-9 mb-0">Total Income</p>
            </>
          </Col>
          <Col xs={6} className="mb-3 text-center">
            <>
              <Unicon
                icon={UilEnvelope}
                className={`text-success mb-1`}
                size={31.25}
              />
              <h1 className="fs-5 mt-3">LKR 5,565</h1>
              <p className="fs-9 mb-0">Total Income</p>
            </>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default EcoimDefaultAddressCard
