import { ColumnDef } from '@tanstack/react-table'
import AdvanceTable from 'components/base/AdvanceTable'
import { Link } from 'react-router-dom'
import AdvanceTableFooter from 'components/base/AdvanceTableFooter'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import useAdvanceTable from 'hooks/useAdvanceTable'

import { useEffect, useState } from 'react'

import apiRequest from 'apiRequest'
import toast, { toastConfig } from 'react-simple-toasts'

export type Customer = {
  id: string
  name: string
  primary_email: string
  primary_contact: string
  phone: string
  currency: string
  address: string
  group: string
  default_language: string
  status?: number
  created_at: string
}

const customersTablecolumns: ColumnDef<Customer>[] = [
  {
    accessorKey: 'id',
    header: '#',
    meta: {
      headerProps: { style: { width: '5%' }, className: 'pe-5 ps-1' },
      cellProps: { className: 'pe-5 ps-1' }
    },
    cell: ({ row: { index } }) => {
      return <p className="mb-0">{index + 1}</p>
    }
  },
  {
    accessorKey: 'name',
    header: 'Company Name',
    cell: ({ row: { original } }) => {
      const { name, id } = original
      return (
        <Link
          to={'/module/clients/client/' + id}
          className="d-flex align-items-center text-body-emphasis"
        >
          <p className="mb-0 fw-bold">{name}</p>
        </Link>
      )
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-5 ps-0' },
      cellProps: { className: 'py-0 pe-5 ps-0 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'primary_contact',
    header: 'Primary Contact',
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-5 ps-1' },
      cellProps: { className: 'pe-5 ps-1' }
    }
  },
  {
    accessorKey: 'primary_email',
    header: 'Primary Email',
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-5 ps-1' },
      cellProps: { className: 'pe-5 ps-1' }
    }
  },
  {
    accessorKey: 'phone',
    header: 'Phone',
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end px-1' },
      cellProps: { className: 'fw-semibold text-body-highlight text-end px-1' }
    }
  },
  {
    accessorKey: 'currency',
    header: 'Currency',
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end ps-3 pe-1' },
      cellProps: { className: 'fw-bold text-body-emphasis ps-3 text-end pe-1' }
    }
  },
  {
    accessorKey: 'group',
    header: 'Group',
    meta: {
      headerProps: { style: { width: '10%' }, className: 'ps-7' },
      cellProps: { className: 'ps-7 text-body-highlight white-space-nowrap' }
    },
    cell: ({ row: { original } }) => {
      if (original.group == null) {
        return <p className="mb-0">-</p>
      }

      for (let i = 0; i < original.group.length; i++) {
        const item: { id: string; name: string } = JSON.parse(original.group[i])

        return <p className="mb-0">{item.name}</p>
      }
    }
  },
  {
    accessorKey: 'status',
    header: 'Active',
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end px-1' },
      cellProps: { className: 'text-body-tertiary text-end px-1' }
    },
    cell: ({ row: { original } }) => {
      return original.status == 1 ? (
        <span className="badge bg-success">Active</span>
      ) : (
        <span className="badge bg-danger">Inactive</span>
      )
    }
  },
  {
    accessorKey: 'created_at',
    header: 'Date Created',
    meta: {
      headerProps: { style: { width: '15%' }, className: 'text-end px-1' },
      cellProps: { className: 'text-body-tertiary text-end px-1' }
    },
    cell: ({ row: { original } }) => {
      return <p className="mb-0">{original.created_at.split('T')[0]}</p>
    }
  }
]

const ClientsTable = (props: {
  updateTable: boolean
  setUpdateTable: () => void
}) => {
  const [preloader, setPreloader] = useState(true)
  const [customers, setCustomers] = useState<Customer[]>([])

  useEffect(() => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/client/get',
      true
    )
    API_REQUEST.send(
      {
        limit: 25,
        page: 0
      },
      (data: { status: string; message: string; data: Customer[] }) => {
        if (data.status == 'success') {
          setCustomers(data.data)
        } else {
          toastConfig({
            position: 'top-right',
            duration: 2000,
            theme: 'light'
          })
          toast(data.message)
        }
        setPreloader(false)
        props.setUpdateTable()
      }
    )
  }, [props.updateTable])

  const table = useAdvanceTable({
    data: customers,
    columns: customersTablecolumns,
    selection: true,
    sortable: true
  })

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          preloader={preloader}
        />
        <AdvanceTableFooter pagination />
      </AdvanceTableProvider>
    </div>
  )
}

export default ClientsTable
