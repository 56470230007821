import Button from 'components/base/Button'
import { Col, Row } from 'react-bootstrap'

import { useEffect, useState } from 'react'

import ItemVariant from './itemVariant'

import { Variant, SelectType } from 'modules/inventory/types'

import apiRequest from 'apiRequest'

const VariantFormCard = ({
  variants,
  setVariants
}: {
  variants: Variant[]
  setVariants: (value: Variant[]) => void
}) => {
  const [variantSelectOptions, setVariantSelectOptions] = useState<
    SelectType[]
  >([])

  useEffect(() => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/items/variants/search',
      true
    )
    API_REQUEST.send(
      { type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setVariantSelectOptions(data.data)
        }
      }
    )
  }, [])

  return (
    <>
      <h4 className="mb-4">Variants</h4>

      <Row
        className="gx-3 gy-4 mb-3"
        style={{ overflowY: 'scroll', height: 225 }}
      >
        <Col xs={12} sm={6} xl={12}>
          {variants.length > 0 &&
            variants.map(item => (
              <ItemVariant
                variant={item}
                variants={variants}
                variantSelectOptions={variantSelectOptions}
                key={variants.indexOf(item)}
                setVariants={setVariants}
                className="border-bottom border-translucent border-dashed border-sm-0 border-bottom-xl pb-4"
              />
            ))}
        </Col>
      </Row>
      <Button
        variant="phoenix-primary"
        className="w-100"
        onClick={() =>
          setVariants([
            ...variants,
            {
              id: '',
              data: []
            }
          ])
        }
      >
        Add option
      </Button>
    </>
  )
}

export default VariantFormCard
