import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/base/Button'
import PageBreadcrumb from 'components/common/PageBreadcrumb'
import { defaultBreadcrumbItems } from 'data/commonData'
import { Col, Row, Form } from 'react-bootstrap'

import EcoimDefaultAddressCard from './components/EcoimDefaultAddressCard'
import EcomProfileCard from './components/EcomProfileCard'
import ProfileDetailsTab from './components/ProfileDetailsTab'
import {
  faEnvelope,
  faKey,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import apiRequest from 'apiRequest'

import { toast } from 'react-toastify'
import Model from 'Model'

export type Customer = {
  id: string
  name: string
  vat_number: string
  website: string
  zip_code: string
  city: string
  state: string
  country: string
  phone: string
  currency: string
  address: string
  last_login: string
  default_language: string
  last_order?: string
}

const Client = () => {
  const navigate = useNavigate()

  const { id } = useParams()
  const [preload, setPreload] = useState(true)
  const [client, setClient] = useState<Customer>({
    id: '',
    name: '',
    vat_number: '',
    phone: '',
    currency: '',
    address: '',
    last_login: '',
    default_language: '',
    last_order: '',
    website: '',
    zip_code: '',
    city: '',
    state: '',
    country: ''
  })
  const [mailSubject, setMailSubject] = useState('')
  const [mailBody, setMailBody] = useState('')
  const [openMailModal, setOpenMailModal] = useState(false)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/client/get',
      true
    )
    API_REQUEST.send(
      { client: id },
      (data: { status: string; message: string; data: Customer }) => {
        if (data.status == 'success') {
          setClient(data.data)
        } else {
          navigate('/module/clients/list', { replace: true })
        }
        setPreload(false)
      }
    )
  }, [id, refresh])

  const deleteCustomer = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        const tmpToast = toast('Deleting', {
          position: 'top-right',
          type: 'error',
          isLoading: true,
          theme: 'light',
          className: 'text-sm'
        })

        const API_REQUEST = new apiRequest(
          'https://demo.globalcloudmedia.com/client/delete',
          true
        )

        API_REQUEST.send(
          { client: id },
          (data: { status: string; message: string }) => {
            if (data.status == 'success') {
              toast.update(tmpToast, {
                render: data.message,
                isLoading: false,
                type: 'success',
                autoClose: 2000
              })

              navigate('/module/clients/list', { replace: true })
            } else {
              toast.update(tmpToast, {
                render: data.message,
                isLoading: false,
                type: 'warning',
                autoClose: 2000
              })
            }
            setPreload(false)
          }
        )
      }
    })
  }

  const sendMail = () => {
    if (mailSubject && mailBody) {
      const tmpToast = toast('Sending mail', {
        position: 'top-right',
        type: 'info',
        isLoading: true,
        theme: 'light',
        className: 'text-sm'
      })

      const API_REQUEST = new apiRequest(
        'https://demo.globalcloudmedia.com/client/send/mail',
        true
      )

      API_REQUEST.send(
        { client: id, subject: mailSubject, message: mailBody },
        (data: { status: string; message: string }) => {
          if (data.status == 'success') {
            toast.update(tmpToast, {
              render: data.message,
              isLoading: false,
              type: 'success',
              autoClose: 2000
            })

            setMailSubject('')
            setMailBody('')
            setOpenMailModal(false)
          } else {
            toast.update(tmpToast, {
              render: data.message,
              isLoading: false,
              type: 'error',
              autoClose: 2000
            })
          }
          setPreload(false)
        }
      )
    }
  }

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <Row className="align-items-center justify-content-between g-3 mb-4">
        <Col xs="auto">
          <h2 className="mb-0">Profile</h2>
        </Col>
        <Col xs="auto" className="d-flex flex-wrap gap-2 gap-sm-3">
          <Button
            onClick={() => setOpenMailModal(true)}
            variant="phoenix-info"
            startIcon={<FontAwesomeIcon className="me-2" icon={faEnvelope} />}
          >
            Send Email
          </Button>
          <Button
            variant="phoenix-danger"
            startIcon={<FontAwesomeIcon className="me-2" icon={faTrashAlt} />}
            onClick={deleteCustomer}
          >
            Delete customer
          </Button>
          <Button
            variant="phoenix-secondary"
            startIcon={<FontAwesomeIcon className="me-2" icon={faKey} />}
          >
            Reset password
          </Button>
        </Col>
      </Row>
      <Row className="g-3 mb-6">
        <Col xs={12} lg={8}>
          <EcomProfileCard client={client} preload={preload} />
        </Col>
        <Col xs={12} lg={4}>
          <EcoimDefaultAddressCard client={client} preload={preload} />
        </Col>
      </Row>
      <ProfileDetailsTab
        client={client}
        preload={preload}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      {openMailModal && (
        <Model
          show={openMailModal}
          setClose={() => setOpenMailModal(false)}
          size="md"
          onClick={sendMail}
          submitTxt="Send Email"
          body={
            <>
              <div className="text-center h5">Send Email</div>
              <hr />
              <Col xs={12} lg={12}>
                <h5 className="text-body-highlight mb-2">Subject</h5>
                <Form.Control
                  onChange={e => setMailSubject(e.target.value)}
                  style={{ width: '100%' }}
                  type="text"
                  id="subject"
                  placeholder="Subject"
                  className="mb-3 w-100"
                />
                <h5 className="text-body-highlight mb-2">Message</h5>
                <Form.Control
                  onChange={e => setMailBody(e.target.value)}
                  style={{ width: '100%' }}
                  id="message"
                  placeholder="Message"
                  className="mb-3"
                  as="textarea"
                />
              </Col>
            </>
          }
        />
      )}
    </div>
  )
}

export default Client
