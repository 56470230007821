import { Col, Row, Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useSpring, animated } from '@react-spring/web'

import apiRequest from 'apiRequest'

import { Customer } from '../../types'

const ProfileContent = (props: {
  client: Customer
  preload: boolean
  setRefresh: (e: boolean) => void
  refresh: boolean
}) => {
  const [propsOpacity] = useSpring(
    () => ({
      from: { opacity: 0, transform: 'translate3d(0, 100%, 0)' },
      to: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
      config: { delay: 100 }
    }),
    []
  )

  const { id } = useParams()

  const updateChanges = (
    e: {
      target: {
        value: string
        id: string
        placeholder: string | null | undefined
      }
    },
    prevValue: string = ''
  ) => {
    const fieldId = e.target.id
    const value = e.target.value

    if (prevValue == value) return

    const tmpToast = toast('Updating ' + e.target.placeholder, {
      position: 'top-right',
      type: 'info',
      isLoading: true,
      theme: 'light',
      className: 'text-sm'
    })

    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/client/update',
      true
    )

    API_REQUEST.send(
      {
        client: id,
        updates: {
          [fieldId]: value
        }
      },
      (data: { status: string; message: string }) => {
        if (data.status == 'success') {
          toast.update(tmpToast, {
            render: data.message,
            isLoading: false,
            type: 'success',
            autoClose: 2000
          })

          props.setRefresh(!props.refresh)
        } else {
          toast.update(tmpToast, {
            render: data.message,
            isLoading: false,
            type: 'error',
            autoClose: 2000
          })
        }
      }
    )
  }

  return (
    <div>
      {props.preload ? (
        <>
          <Row className="align-items-center justify-content-between g-2">
            <Col xs={12} lg={6}>
              <SkeletonTheme highlightColor="#444">
                <Skeleton
                  count={5}
                  style={{ opacity: 0.2, marginTop: '20px' }}
                  duration={2}
                />
              </SkeletonTheme>
            </Col>
            <Col xs={12} lg={6}>
              <SkeletonTheme highlightColor="#444">
                <Skeleton
                  count={5}
                  style={{ opacity: 0.2, marginTop: '20px' }}
                  duration={2}
                />
              </SkeletonTheme>
            </Col>
          </Row>
        </>
      ) : (
        <Row
          style={{
            paddingTop: '1%',
            paddingLeft: '0.5%',
            paddingRight: '0.5%',
            paddingBottom: '1%',
            borderRadius: '10px',
            backgroundColor: '#fff'
          }}
          className="light__bg-light dark__text-gray-100 dark__bg-gray-1100"
          bg="light"
        >
          <animated.div style={propsOpacity}>
            <Row className="align-items-center justify-content-between g-2">
              <Col xs={12} lg={4}>
                <h5 className="text-body-highlight mb-2">Company Name</h5>
                <Form.Control
                  style={{ width: '100%' }}
                  id="company_name"
                  placeholder="Company Name"
                  data-label="Company Name"
                  onBlur={e => updateChanges(e, props.client.name)}
                  defaultValue={props.client.name}
                  required
                />
              </Col>
              <Col xs={12} lg={4}>
                <h5 className="text-body-highlight mb-2">VAT Number</h5>
                <Form.Control
                  style={{ width: '100%' }}
                  id="vat_number"
                  data-label="VAT Number"
                  defaultValue={props.client.vat_number}
                  placeholder="VAT Number"
                  onBlur={e => updateChanges(e, props.client.vat_number)}
                />
              </Col>
              <Col xs={12} lg={4}>
                <h5 className="text-body-highlight mb-2">Website</h5>
                <Form.Control
                  style={{ width: '100%' }}
                  id="website"
                  data-label="Website"
                  defaultValue={props.client.website}
                  placeholder="Website"
                  onBlur={e => updateChanges(e, props.client.website)}
                />
              </Col>
              <Row className="align-items-center justify-content-between g-2">
                <Col xs={12} lg={12}>
                  <h5 className="text-body-highlight mb-2">Address</h5>
                  <Form.Control
                    style={{ width: '100%' }}
                    id="address"
                    data-label="Address"
                    placeholder="Address"
                    defaultValue={props.client.address}
                    as={'textarea'}
                    onBlur={e => updateChanges(e, props.client.address)}
                  />
                </Col>
              </Row>
              <Row className="align-items-center justify-content-between g-2">
                <Col xs={12} lg={4}>
                  <h5 className="text-body-highlight mb-2">Phone</h5>
                  <Form.Control
                    style={{ width: '100%' }}
                    id="phone"
                    data-label="Phone"
                    placeholder="Phone"
                    defaultValue={props.client.phone}
                    onBlur={e => updateChanges(e, props.client.phone)}
                  />
                </Col>
                <Col xs={12} lg={4}>
                  <h5 className="text-body-highlight mb-2">City</h5>
                  <Form.Control
                    style={{ width: '100%' }}
                    id="city"
                    data-label="City"
                    placeholder="City"
                    defaultValue={props.client.city}
                    onBlur={e => updateChanges(e, props.client.city)}
                  />
                </Col>
                <Col xs={12} lg={4}>
                  <h5 className="text-body-highlight mb-2">State</h5>
                  <Form.Control
                    style={{ width: '100%' }}
                    id="state"
                    data-label="state"
                    placeholder="State"
                    defaultValue={props.client.state}
                    onBlur={e => updateChanges(e, props.client.state)}
                  />
                </Col>
              </Row>
              <Row className="align-items-center justify-content-between g-2">
                <Col xs={12} lg={4}>
                  <h5 className="text-body-highlight mb-2">Zip Code</h5>
                  <Form.Control
                    style={{ width: '100%' }}
                    id="zip_code"
                    data-label="Zip Code"
                    placeholder="Zip Code"
                    defaultValue={props.client.zip_code}
                    onBlur={e => updateChanges(e, props.client.zip_code)}
                  />
                </Col>
                <Col xs={12} lg={4}>
                  <h5 className="text-body-highlight mb-2">Country</h5>
                  <Form.Control
                    style={{ width: '100%' }}
                    id="country"
                    data-label="Country"
                    placeholder="Country"
                    defaultValue={props.client.country}
                    onBlur={e => updateChanges(e, props.client.country)}
                  />
                </Col>
                <Col xs={12} lg={4}></Col>
              </Row>
            </Row>
            <Row className="align-items-center justify-content-between g-2">
              <Col xs={4} lg={12}></Col>
              <Col xs={4} lg={12}></Col>
              <Col xs={4} lg={12}></Col>
            </Row>
          </animated.div>
        </Row>
      )}
    </div>
  )
}

export default ProfileContent
