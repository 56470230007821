import { ColumnDef } from '@tanstack/react-table'
import AdvanceTable from 'components/base/AdvanceTable'
// import { Link } from 'react-router-dom'
import AdvanceTableFooter from 'components/base/AdvanceTableFooter'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import useAdvanceTable from 'hooks/useAdvanceTable'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

import apiRequest from 'apiRequest'
import toast, { toastConfig } from 'react-simple-toasts'

import { Invoice, DB_Invoice } from 'modules/sales/Types'
import { Badge } from 'react-bootstrap'

const InvoicesTable = (props: {
  updateTable: boolean
  refreshPreview: boolean
  setUpdateTable: () => void
  showPreview: (e: boolean) => void
  setInvoiceId: (e: string) => void
  setRefreshPreview: (e: boolean) => void
}) => {
  const navigate = useNavigate()

  const customersTablecolumns: ColumnDef<Invoice>[] = [
    {
      accessorKey: 'id',
      header: '#',
      meta: {
        headerProps: { style: { width: '5%' }, className: 'pe-5 ps-0' },
        cellProps: { className: 'py-0 pe-5 ps-0 white-space-nowrap' }
      },
      cell: ({ row: { original } }) => {
        const { invoice_number, id } = original

        return (
          <Link
            to="#"
            onClick={() => {
              props.showPreview(true)
              props.setInvoiceId(id)

              props.setRefreshPreview(!props.refreshPreview)
            }}
            className="d-flex align-items-center text-body-emphasis"
          >
            <p className="mb-0 fw-bold">{invoice_number}</p>
          </Link>
        )
      }
    },
    {
      accessorKey: 'customer',
      header: 'Customer',
      meta: {
        headerProps: { style: { width: '25%' }, className: 'ps-7' },
        cellProps: { className: 'ps-7 text-body-highlight white-space-nowrap' }
      },
      cell: ({ row: { original } }) => {
        const { customer } = original

        return (
          <Link
            to="#"
            onClick={() => {
              navigate(`/module/clients/client/${customer.id}`, {
                replace: true
              })
            }}
            className="d-flex align-items-center text-body-emphasis"
          >
            <p className="mb-0 fw-bold">{customer.name}</p>
          </Link>
        )
      }
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      meta: {
        headerProps: { style: { width: '10%' }, className: 'pe-5 ps-1' },
        cellProps: { className: 'pe-5 ps-1' }
      },
      cell: ({ row: { original } }) => {
        const { amount } = original

        return <p className="mb-0 fw-bold text-body-highlight">{amount}</p>
      }
    },
    {
      accessorKey: 'total_tax',
      header: 'Total Tax',
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end px-1' },
        cellProps: {
          className: 'fw-semibold text-body-highlight text-end px-1'
        }
      }
    },
    {
      accessorKey: 'date',
      header: 'Date',
      meta: {
        headerProps: {
          style: { width: '10%' },
          className: 'text-end ps-3 pe-1'
        },
        cellProps: {
          className: 'fw-bold text-body-emphasis ps-3 text-end pe-1'
        }
      }
    },
    {
      accessorKey: 'project',
      header: 'Project',
      meta: {
        headerProps: { style: { width: '15%' }, className: 'text-end px-1' },
        cellProps: { className: 'text-body-tertiary text-end px-1' }
      }
    },
    {
      accessorKey: 'tags',
      header: 'Tags',
      meta: {
        headerProps: { style: { width: '15%' }, className: 'text-end px-1' },
        cellProps: { className: 'text-body-tertiary text-end px-1' }
      }
    },
    {
      accessorKey: 'due_date',
      header: 'Due Date',
      meta: {
        headerProps: {
          style: { width: '10%', minWidth: 150 },
          className: 'text-end'
        },
        cellProps: { className: 'text-body-tertiary text-end pe-0' }
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      meta: {
        headerProps: {
          style: { width: '10%', minWidth: 150 },
          className: 'text-end'
        },
        cellProps: { className: 'text-body-tertiary text-end pe-0' }
      },
      cell: ({ row: { original } }) => {
        const { status } = original
        let status_badge = <Badge bg="success">Paid</Badge>
        if (status == 0) {
          status_badge = <Badge bg="danger">Unpaid</Badge>
        } else if (status == 1) {
          status_badge = <Badge bg="secondary">Draft </Badge>
        } else if (status == 3) {
          status_badge = <Badge bg="warning">Cancelled</Badge>
        }
        return status_badge
      }
    }
  ]

  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [preloader, setPreloader] = useState(true)

  useEffect(() => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/sales/invoices/get',
      true
    )
    API_REQUEST.send(
      {
        limit: 25,
        page: 0
      },
      (data: { status: string; message: string; data: DB_Invoice[] }) => {
        if (data.status == 'success') {
          const invoices: Invoice[] = []
          data.data.forEach(element => {
            invoices.push({
              id: element.id,
              invoice_number: element.invoice_number,
              customer: element.client,
              amount: element.total,
              total_tax: element.tax_amount,
              date: element.invoice_date,
              project: element.project ? element.project.name : '',
              tags: element.tags,
              due_date: element.due_date,
              status: element.status
            })
          })

          setInvoices(invoices)
        } else {
          toastConfig({
            position: 'top-right',
            duration: 2000,
            theme: 'light'
          })
          toast(data.message)
        }
        setPreloader(false)
        props.setUpdateTable()
      }
    )
  }, [props.updateTable])

  const table = useAdvanceTable({
    data: invoices,
    columns: customersTablecolumns,
    selection: true,
    sortable: true
  })

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          preloader={preloader}
        />
        <AdvanceTableFooter pagination />
      </AdvanceTableProvider>
    </div>
  )
}

export default InvoicesTable
