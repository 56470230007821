import { ColumnDef } from '@tanstack/react-table'
import AdvanceTable from 'components/base/AdvanceTable'
import { currencyFormat } from 'helpers/utils'
import { Link } from 'react-router-dom'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import AdvanceTableFooter from 'components/base/AdvanceTableFooter'
import useAdvanceTable from 'hooks/useAdvanceTable'

import Badge from 'components/base/Badge'
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown'
import ActionDropdownItems from 'components/common/ActionDropdownItems'
import { useEffect, useState } from 'react'

import { Item } from '../types'

import apiRequest from 'apiRequest'

import toast, { toastConfig } from 'react-simple-toasts'

export const productsTablecolumns: ColumnDef<Item>[] = [
  {
    id: 'productImage',
    accessorKey: '',
    cell: ({ row: { original } }) => {
      const { id, productImage } = original
      return (
        <Link
          to={'/module/inventory/item/' + id}
          className="rounded-2 border border-translucent d-inline-block"
        >
          <img src={productImage} alt="" width={53} />
        </Link>
      )
    },
    meta: {
      headerProps: { style: { width: 70 } },
      cellProps: { className: 'py-0' }
    },
    enableSorting: false
  },
  {
    accessorKey: 'product',
    header: 'Product name',
    cell: ({ row: { original } }) => {
      const { id, name } = original
      return (
        <Link
          to={'/module/inventory/item/' + id}
          className="fw-semibold line-clamp-3"
        >
          {name}
        </Link>
      )
    },
    meta: {
      headerProps: { style: { width: 350 }, className: 'ps-4 text-start' },
      cellProps: { className: 'ps-4' }
    }
  },
  {
    id: 'price',
    accessorFn: ({ default_price, priceMax, priceMin }) =>
      `${default_price} ${priceMax} ${priceMin}`,
    header: 'Price',
    cell: ({ row: { original } }) => {
      const { default_price, priceMax, priceMin } = original
      return default_price
        ? currencyFormat(default_price)
        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          `${currencyFormat(priceMin!)} - ${currencyFormat(priceMax!)}`
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4 text-end' },
      cellProps: { className: 'fw-bold ps-4 text-body-tertiary text-end' }
    }
  },
  {
    accessorKey: 'groups',
    header: 'Groups',
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: {
        className: 'fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85'
      }
    },
    cell: ({ row: { original } }) => {
      const { groups } = original
      return (
        <div className="d-flex flex-wrap gap-2">
          {groups
            ? groups.map(group => (
                <Link key={group.id} to="#!" className="text-decoration-none">
                  <Badge variant="tag">{group.name}</Badge>
                </Link>
              ))
            : '-'}
        </div>
      )
    }
  },
  {
    id: 'tags',
    accessorFn: ({ tags }) => tags?.join(''),
    header: 'Tags',
    cell: ({ row: { original } }) => {
      const { tags } = original
      return (
        <div className="d-flex flex-wrap gap-2">
          {tags
            ? tags.map(tag => (
                <Link key={tag.id} to="#!" className="text-decoration-none">
                  <Badge variant="tag">{tag.name}</Badge>
                </Link>
              ))
            : '-'}
        </div>
      )
    },
    meta: {
      headerProps: { style: { width: 250 }, className: 'ps-3' },
      cellProps: { style: { minWidth: 225 }, className: 'ps-3' }
    }
  },
  {
    id: 'code',
    header: 'Code',
    accessorKey: 'code',
    meta: {
      headerProps: { style: { width: 125 }, className: 'ps-4 text-left' },
      cellProps: { className: 'ps-4 text-center' }
    }
  },
  {
    accessorKey: 'vendor',
    header: 'Vendor',
    cell: ({ row: { original } }) => {
      const { vendors } = original
      return (
        <div className="d-flex flex-wrap gap-2">
          {vendors
            ? vendors.map(vendor => (
                <Link key={vendor.id} to="#!" className="text-decoration-none">
                  <Badge variant="tag">{vendor.name}</Badge>
                </Link>
              ))
            : '-'}
        </div>
      )
    },
    meta: {
      headerProps: { style: { width: 200 }, className: 'ps-4' },
      cellProps: { className: 'ps-4 fw-semibold text-start' }
    }
  },
  {
    accessorKey: 'created_at',
    header: 'Published on',
    meta: {
      headerProps: { style: { width: 50 }, className: 'ps-4' },
      cellProps: { className: 'text-body-tertiary text-opacity-85 ps-4' }
    }
  },
  {
    id: 'action',
    cell: () => (
      <RevealDropdownTrigger style={{ zIndex: 999 }}>
        <RevealDropdown>
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '7%' } },
      cellProps: { className: 'text-end' }
    }
  }
]

const ItemsTable = (props?: {
  updateTable?: boolean
  setUpdateTable?: () => void
}) => {
  const [items, setItems] = useState<Item[]>([])
  const [preloader, setPreloader] = useState(true)

  useEffect(() => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/items/get',
      true
    )
    API_REQUEST.send(
      {
        limit: 25,
        page: 0
      },
      (data: { status: string; message: string; data: Item[] }) => {
        if (data.status == 'success') {
          setItems(data.data)
        } else {
          toastConfig({
            position: 'top-right',
            duration: 2000,
            theme: 'light'
          })
          toast(data.message)
        }
        setPreloader(false)
        props?.setUpdateTable?.()
      }
    )
  }, [props?.updateTable])

  const table = useAdvanceTable({
    data: items,
    columns: productsTablecolumns,
    selection: true,
    sortable: true
  })

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          preloader={preloader}
        />
        <AdvanceTableFooter pagination />
      </AdvanceTableProvider>
    </div>
  )
}

export default ItemsTable
