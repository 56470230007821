import {
  faEnvelopeOpen,
  faExpand,
  faEye,
  faFileExport,
  faCompress,
  faPlus,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/base/Button'
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup'
import FilterTab, { FilterTabItem } from 'components/common/FilterTab'
import PageBreadcrumb from 'components/common/PageBreadcrumb'
import SearchBox from 'components/common/SearchBox'
import { customersTablecolumns } from 'components/tables/CustomersTable'
import { defaultBreadcrumbItems } from 'data/commonData'
import { customers } from 'data/e-commerce/customers'
import useAdvanceTable from 'hooks/useAdvanceTable'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import { ChangeEvent, useEffect } from 'react'

import InvoicesTable from './components/InvoicesTable'
import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'

import InvoicePreview from 'modules/sales/components/invoices/invoice_preview'

const tabItems: FilterTabItem[] = [
  {
    label: 'Draft',
    value: 'draft',
    count: 68
  },
  {
    label: 'Paid',
    value: 'paid',
    count: 6
  },
  {
    label: 'Unpaid',
    value: 'unpaid',
    count: 17
  },
  {
    label: 'Due',
    value: 'due',
    count: 60
  }
]

const filterMenus: FilterMenu[] = [
  {
    label: 'Country',
    items: [
      {
        label: 'USA'
      },
      {
        label: 'UK'
      },
      {
        label: 'Australia'
      }
    ]
  },
  {
    label: 'Status',
    items: [
      {
        label: 'Draft'
      },
      {
        label: 'Paid'
      },
      {
        label: 'Due'
      },
      {
        label: 'Unpaid'
      }
    ]
  }
]

const InvoicesList = () => {
  const { id } = useParams()

  const table = useAdvanceTable({
    data: customers,
    columns: customersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  })

  const [refreshPreview, setRefreshPreview] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [showPreviewFull, setShowPreviewFull] = useState(false)
  const [invoiceId, setInvoiceId] = useState('')

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined)
  }

  const [modelOpen, setModelOpen] = useState(false)
  const [updateTable, setUpdateTable] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (id != undefined && id != null) {
      setInvoiceId(id)
      setShowPreview(true)
    }
  }, [id])

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-4">Invoices</h2>
        <FilterTab tabItems={tabItems} className="mb-2" />

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <div className="d-flex">
                <Button
                  variant="primary"
                  onClick={() =>
                    navigate('/module/sales/invoices/invoice', {
                      replace: true
                    })
                  }
                  className="me-2"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add Invoice
                </Button>
              </div>
              <div className="scrollbar overflow-hidden-y">
                <FilterButtonGroup menus={filterMenus} />
              </div>
              <div className="ms-xxl-auto">
                <div className="d-flex flex-wrap gap-3">
                  <div className="d-flex">
                    <SearchBox
                      placeholder="Search invoices"
                      onChange={handleSearchInputChange}
                    />
                    <Button
                      variant="secondary"
                      onClick={() => setModelOpen(true)}
                      className="ms-2"
                    >
                      <FontAwesomeIcon
                        icon={faFileExport}
                        className="fs-9 me-2 mr-1"
                      />
                      Export
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Row className="mx-n4 mx-lg-n6 px-lg-6">
            <Col
              xs={12}
              sm={12}
              md={!showPreview ? 12 : 4}
              lg={!showPreview ? 12 : 4}
              style={{ display: showPreviewFull ? 'none' : '' }}
            >
              <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                <InvoicesTable
                  updateTable={updateTable}
                  setUpdateTable={() => setUpdateTable(!modelOpen)}
                  showPreview={(e: boolean) => setShowPreview(e)}
                  setInvoiceId={(e: string) => setInvoiceId(e)}
                  setRefreshPreview={() => setRefreshPreview(!refreshPreview)}
                  refreshPreview={refreshPreview}
                />
              </div>
            </Col>
            {showPreview && (
              <Col
                xs={12}
                sm={12}
                md={showPreviewFull ? 12 : 8}
                lg={showPreviewFull ? 12 : 8}
              >
                <div className="mx-3">
                  <div className="h-100 w-100 bg-body-emphasis">
                    <Tab.Container
                      id="invoice-preview"
                      defaultActiveKey="invoice"
                    >
                      <Nav variant="underline" className="pb-3 pt-3 mx-3">
                        <Nav.Item>
                          <Nav.Link eventKey="invoice">Invoice</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="task">Tasks</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="activity_log">
                            Activity Log
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="reminder">Reminders</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="notes">Notes</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="ms-auto text-center">
                          <Nav.Link eventKey="email">
                            <FontAwesomeIcon
                              icon={faEnvelopeOpen}
                              className="fs-9 me-2 mr-1"
                            />
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="text-center">
                          <Nav.Link eventKey="view">
                            <FontAwesomeIcon
                              icon={faEye}
                              className="fs-9 me-2 mr-1"
                            />
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="text-center">
                          <Nav.Link
                            eventKey="invoice"
                            onClick={() => {
                              setShowPreviewFull(!showPreviewFull)
                            }}
                          >
                            <FontAwesomeIcon
                              icon={!showPreviewFull ? faExpand : faCompress}
                              className="fs-9 me-2 mr-1"
                            />
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="text-center">
                          <Nav.Link
                            eventKey="close"
                            onClick={() => {
                              setShowPreview(false)
                              setShowPreviewFull(false)
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faArrowLeft}
                              className="fs-9 me-2 mr-1"
                            />
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="invoice">
                          <InvoicePreview
                            invoiceid={invoiceId}
                            setInvoiceId={setInvoiceId}
                            showPreview={e => setShowPreview(e)}
                            refreshPreview={refreshPreview}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="task"></Tab.Pane>
                        <Tab.Pane eventKey="activity_log"></Tab.Pane>
                        <Tab.Pane eventKey="reminder"></Tab.Pane>
                        <Tab.Pane eventKey="notes"></Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </AdvanceTableProvider>
      </div>
    </div>
  )
}

export default InvoicesList
